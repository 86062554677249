.view-company-items {
  padding: 20px;
  background-color: #242526;
  color: #E8E6E3;
}

.view-company-items h1, .view-company-items h2 {
  margin-bottom: 20px;
  color: #E8E6E3;
}

.view-company-items table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 30px;
}

.view-company-items th, .view-company-items td {
  border: 1px solid #3A3B3C;
  padding: 12px;
  text-align: left;
}

.view-company-items th {
  background-color: #3A3B3C;
  color: #E8E6E3;
  font-weight: bold;
}

.view-company-items tr:nth-child(even) {
  background-color: #2C2D2E;
}

.view-company-items tr:nth-child(odd) {
  background-color: #242526;
}

.view-company-items a {
  color: #4E9EF5;
  text-decoration: none;
  transition: color 0.2s;
}

.view-company-items a:hover {
  color: #6FB2FF;
  text-decoration: underline;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .view-company-items {
    padding: 10px;
  }

  .view-company-items table {
    font-size: 14px;
  }

  .view-company-items th, .view-company-items td {
    padding: 8px;
  }
}
