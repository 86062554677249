.view-companies {
  padding: 20px;
  background-color: #242526;
  color: #E8E6E3;
}

.view-companies h1 {
  margin-bottom: 20px;
  color: #E8E6E3;
}

.view-companies table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 8px;
  overflow: hidden;
}

.view-companies th, .view-companies td {
  border: 1px solid #3A3B3C;
  padding: 12px;
  text-align: left;
}

.view-companies th {
  background-color: #3A3B3C;
  color: #E8E6E3;
  font-weight: bold;
}

.view-companies tr:nth-child(even) {
  background-color: #2C2D2E;
}

.view-companies tr:nth-child(odd) {
  background-color: #242526;
}

.view-companies a {
  color: #4E9EF5;
  text-decoration: none;
  transition: color 0.2s;
}

.view-companies a:hover {
  color: #6FB2FF;
  text-decoration: underline;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .view-companies {
    padding: 10px;
  }

  .view-companies table {
    font-size: 14px;
  }

  .view-companies th, .view-companies td {
    padding: 8px;
  }
}
