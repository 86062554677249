/** Body */
body {
  background-color: #181A1B;
  color: #E8E6E3; /* Light text color for better contrast */
}


body {
  scrollbar-width: thin;
  scrollbar-color: #888 #2e3032;
}

body::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

body::-webkit-scrollbar-track {
  background: #2e3032;
}

body::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

body::-webkit-scrollbar-thumb:hover {
  background: #555;
}


/* Update these existing styles */
.main-layout {
  background-color: #181A1B;
}

/** Header, Footer, Main Header, Main Footer */
.main-layout header,
.main-layout footer,
.main-header,
.main-footer {
  background-color: #242526; /* Slightly lighter than the main background for contrast */
}

.main-layout-main {
  background-color: #f0f3f6;
}

/** Feature Item, User Profile, Rewards Overview, Recent Transactions, Available Rewards, Edit Profile */
.feature-item,
.user-profile,
.rewards-overview,
.recent-transactions,
.available-rewards,
.edit-profile {
  background-color: #242526;
  color: #E8E6E3;
}

/** Signup Container, Login Container, Company Signup Container */
.signup-container,
.login-container,
.company-signup-container {
  background-color: #181A1B;
}

/** Signup Form, Login Form, Company Signup Form */
.signup-form,
.login-form,
.company-signup-form {
  background-color: #242526;
  color: #E8E6E3;
}

/** Signup Form, Login Form, Company Signup Form Inputs */
.signup-form input,
.login-form input,
.company-signup-form input,
.edit-profile input {
  background-color: #3A3B3C;
  color: #E8E6E3;
  border-color: #4E4F50;
}

/** Button Primary, Signup Button, Login Button, Company Signup Button, Edit Profile Button */
.btn-primary,
.signup-button,
.login-button,
.company-signup-button,
.edit-profile button[type="submit"] {
  background-color: #4A4B4C;
  color: #E8E6E3;
}

/** Button Primary, Signup Button, Login Button, Company Signup Button, Edit Profile Button Hover */
.btn-primary:hover,
.signup-button:hover,
.login-button:hover,
.company-signup-button:hover,
.edit-profile button[type="submit"]:hover {
  background-color: #5A5B5C;
}

/** PWA Section */
.pwa-section {
  background-color: #242526;
  color: #E8E6E3;
}

/** Link Colors for better visibility */
a {
  color: #4E9EF5;
}

a:hover {
  color: #6FB2FF;
}

/** Update the language select dropdown */
.menu-wrapper select {
  background-color: #3A3B3C;
  color: #E8E6E3;
  border-color: #4E4F50;
}

/** Update the burger menu colors */
.bm-burger-bars {
  background: #E8E6E3;
}

.bm-menu {
  background: #242526;
}

.bm-item {
  color: #E8E6E3;
}

.bm-item:hover {
  color: #6FB2FF;
}

/** Main Layout */
.main-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-layout header {
  background-color: #242526;
  color: white;
  padding: 1rem;
}

.main-layout main {
  flex: 1;
  padding: 1rem;
}

.main-layout footer {
  background-color: #242526;
  color: white;
  padding: 1rem;
  text-align: center;
}

.App {
  text-align: center;
}

/** App Logo */
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/** App Header */
.App-header {
  background-color: #242526;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

/** App Link */
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/** Mobile-friendly adjustments */
@media (max-width: 768px) {
  .main-header h1 {
    font-size: 1.2rem;
  }

  .main-layout main {
    padding: 0.0rem;
  }

  .main-footer {
    font-size: 0.9rem;
  }
}

/** Header styles */
.main-header {
  background-color: #242526;
  color: white;
  padding: 1rem 0;
}

.main-header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px; /* Add some padding */
}

.main-header h1 {
  margin: 0;
  font-size: 1.5rem;
}

/** Footer styles */
.main-footer {
  background-color: #242526;
  color: white;
  padding: 1rem 0;
}

.main-footer .container {
  text-align: center;
}

.main-footer nav ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.main-footer nav ul li {
  margin: 0 10px;
}

.main-footer nav ul li a {
  color: white;
  text-decoration: none;
}

/** Burger menu styles */
.bm-burger-button {
  position: relative; /* Change from fixed to relative */
  width: 30px;
  height: 24px;
  right: 0; /* Remove right positioning */
  top: 0; /* Remove top positioning */
}

.bm-burger-bars {
  background: #E8E6E3;
}

.bm-cross-button {
  height: 24px;
  width: 24px;
}

.bm-cross {
  background: #bdc3c7;
}

.bm-menu-wrap {
  position: fixed;
  height: 100%;
  top: 0;
  right: 0; /* Ensure the menu opens from the right side */
}

.bm-menu {
  background: #242526;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

.bm-item {
  display: block; /* Change from inline-block to block */
  color: #E8E6E3;
  padding: 10px 0;
  font-size: 1.2em;
  text-align: left;
  text-decoration: none;
  transition: color 0.2s;
}

.bm-item:hover {
  color: #6FB2FF;
}


/** USER DASHBOARD */
.user-dashboard {
  background: linear-gradient(to bottom, #3498db 0%, #3498db 10%, #f0f3f6 20%);  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.dashboard-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
}

.user-profile,
.rewards-overview,
.recent-transactions,
.available-rewards {
  background-color: #242526;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.user-avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 1rem;
}

.rewards-stats {
  display: flex;
  justify-content: space-around;
}

.stat {
  text-align: center;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  margin-bottom: 1rem;
}

.btn {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

.btn-primary {
  background-color: #4A4B4C;
  color: #E8E6E3;
}

.btn-secondary {
  background-color: #6c757d;
  color: white;
}

@media (max-width: 768px) {
  .dashboard-grid {
    grid-template-columns: 1fr;
  }
}

/** SIGNUP Modal */

.signup-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #181A1B;
}

.signup-form {
  background-color: #242526;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 396px;
}

.signup-logo {
  display: block;
  margin: 0 auto 20px;
  max-width: 200px;
}

.signup-form h2 {
  color: #E8E6E3;
  font-size: 24px;
  font-weight: normal;
  line-height: 32px;
  text-align: center;
  margin-bottom: 20px;
}

.signup-form input {
  width: 100%;
  padding: 14px 16px;
  font-size: 17px;
  border: 1px solid #dddfe2;
  border-radius: 6px;
  margin-bottom: 12px;
}

.signup-button {
  background-color: #4A4B4C;
  border: none;
  border-radius: 6px;
  color: #E8E6E3;
  font-size: 20px;
  font-weight: bold;
  padding: 14px 16px;
  width: 100%;
  cursor: pointer;
  margin-top: 10px;
}

.signup-button:hover {
  background-color: #5A5B5C;
}

.error-message {
  color: #ff0000;
  text-align: center;
  margin-top: 10px;
}

.login-link {
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
}

.login-link a {
  color: #4E9EF5;
  text-decoration: none;
}

.login-link a:hover {
  text-decoration: underline;
}

/** LOGIN Password Input */

.password-input-wrapper {
  position: relative;
}

.password-input-wrapper input {
  padding-right: 40px; /* Make room for the eye icon */
}

.toggle-password {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
  color: #666;
}

.toggle-password:focus {
  outline: none;
}

/** Login Modal  */

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #181A1B;
}

.login-form {
  background-color: #242526;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 396px;
}

.login-logo {
  display: block;
  margin: 0 auto 20px;
  max-width: 200px;
}

.login-form h2 {
  font-size: 24px;
  font-weight: normal;
  line-height: 32px;
  text-align: center;
  margin-bottom: 20px;
}

.login-form input {
  width: 100%;
  padding: 14px 16px;
  font-size: 17px;
  border: 1px solid #dddfe2;
  border-radius: 6px;
  margin-bottom: 12px;
}

.login-button {
  background-color: #4A4B4C;
  border: none;
  border-radius: 6px;
  color: #E8E6E3;
  font-size: 20px;
  font-weight: bold;
  padding: 14px 16px;
  width: 100%;
  cursor: pointer;
  margin-top: 10px;
}

.login-button:hover {
  background-color: #5A5B5C;
}

.signup-link {
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
}

.signup-link a {
  color: #4E9EF5;
  text-decoration: none;
}

.signup-link a:hover {
  text-decoration: underline;
}

/**Logged Out Header  */

.logged-out-header {
  background-color: #3498db;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 0.5rem 0;
}

.logged-out-header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.logged-out-header .logo-link {
  display: flex;
  align-items: center;
}

.logged-out-header .logo {
  height: 40px;
  width: auto;
}

.logged-out-header nav {
  display: flex;
  gap: 1rem;
}

.logged-out-header .auth-link {
  color: #4E9EF5;
  text-decoration: none;
  font-weight: bold;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.logged-out-header .auth-link:hover {
  background-color: #e7f3ff;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .logged-out-header .container {
    flex-direction: column;
    gap: 1rem;
  }
}

.install-button {
  background-color: #4E9EF5;
  color: #E8E6E3;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  margin-right: 15px;
}

.logged-out-header .container,
.main-header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/** PWA Section */

.pwa-section {
  text-align: center;
  margin-top: 2rem;
  padding: 2rem;
  background-color: #242526;
  color: #E8E6E3;
}

/** Edit Profile Styles */
.edit-profile {
  max-width: 600px;
  margin: 0 auto;
  padding: 2rem;
  background-color: #242526;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.edit-profile h2 {
  text-align: center;
  margin-bottom: 1.5rem;
  color: #E8E6E3;
}

.edit-profile form {
  display: flex;
  flex-direction: column;
}

.edit-profile .form-group {
  margin-bottom: 1rem;
}

.edit-profile label {
  font-weight: bold;
  margin-bottom: 0.5rem;
  display: block;
}

.edit-profile input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 1rem;
}

.edit-profile button[type="submit"] {
  background-color: #4A4B4C;
  color: #E8E6E3;
  border: none;
  padding: 0.75rem;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.edit-profile button[type="submit"]:hover {
  background-color: #5A5B5C;
}

.avatar-selection {
  margin-bottom: 1.5rem;
}

.avatar-selection h3 {
  margin-bottom: 0.5rem;
}

.avatar-options {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  justify-content: center;
}

.avatar-options .MuiAvatar-root {
  width: 60px;
  height: 60px;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
}

.avatar-options .MuiAvatar-root:hover {
  transform: scale(1.1);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.avatar-options .MuiAvatar-root.selected {
  border: 2px solid #4A4B4C;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .edit-profile {
    padding: 1rem;
  }

  .avatar-options .MuiAvatar-root {
    width: 50px;
    height: 50px;
  }
}

/** Menu Wrapper */

.menu-wrapper {
  display: flex;
  align-items: center;
}

/** Style the language select */
.menu-wrapper select {
  margin-right: 15px;
  padding: 5px;
  background-color: #3A3B3C;
  border: 1px solid #4E4F50;
  border-radius: 4px;
}

/** Media Query for Mobile Devices */
@media (max-width: 768px) {
  .main-header .container {
    flex-wrap: wrap;
  }

  .menu-wrapper {
    width: 100%;
    justify-content: flex-end;
    margin-top: 10px;
  }

  .bm-burger-button {
    top: 10px; /* Adjust top positioning for mobile */
  }
}

/** Company Signup Modal */

.company-signup-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #181A1B;
}

.company-signup-form {
  background-color: #242526;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 396px;
}

.company-signup-form h2 {
  font-size: 24px;
  font-weight: normal;
  line-height: 32px;
  text-align: center;
  margin-bottom: 20px;
}

.company-signup-form input {
  width: 100%;
  padding: 14px 16px;
  font-size: 17px;
  border: 1px solid #dddfe2;
  border-radius: 6px;
  margin-bottom: 12px;
}

.company-signup-button {
  background-color: #4A4B4C;
  border: none;
  border-radius: 6px;
  color: #E8E6E3;
  font-size: 20px;
  font-weight: bold;
  padding: 14px 16px;
  width: 100%;
  cursor: pointer;
  margin-top: 10px;
}

.company-signup-button:hover {
  background-color: #5A5B5C;
}

.company-signup-form .login-link {
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
}

.company-signup-form .login-link a {
  color: #4E9EF5;
  text-decoration: none;
}

.company-signup-form .login-link a:hover {
  text-decoration: underline;
}

/** BusinessProfile Settings */

.profile-settings-container {
  overflow-x: hidden;
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  background-color: #2C2D2E;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.profile-settings-container h4 {
  color: #E8E6E3;
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
}

.profile-settings-container .MuiFormControl-root {
  margin-bottom: 1.5rem;
}

.profile-settings-container .MuiInputLabel-root {
  color: #B0B3B8;
}

.profile-settings-container .MuiOutlinedInput-root {
  background-color: #3A3B3C;
}

.profile-settings-container .MuiOutlinedInput-notchedOutline {
  border-color: #4E4F50;
}

.profile-settings-container .MuiOutlinedInput-input {
  color: #E8E6E3;
}

.profile-settings-container .MuiSelect-icon {
  color: #B0B3B8;
}

.profile-settings-container .MuiMenuItem-root {
  color: #E8E6E3;
}

.profile-settings-container .MuiButton-contained {
  background-color: #4A4B4C;
  color: #E8E6E3;
  margin-top: 1rem;
}

.profile-settings-container .MuiButton-contained:hover {
  background-color: #5A5B5C;
}

.avatar-upload-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
  background-color: #3A3B3C;
  padding: 1.5rem;
  border-radius: 8px;
}

.avatar-upload-section .MuiAvatar-root {
  width: 120px;
  height: 120px;
  margin-bottom: 1rem;
}

.avatar-upload-section .MuiButton-root {
  margin-top: 1rem;
}

@media (max-width: 600px) {
  .profile-settings-container {
    padding: 1rem;
  }
  .user-dashboard {
    margin-bottom: 56px;
  }
  .edit-profile {
    margin-bottom: 56px;
  }
  .manage-my-points {
    margin-bottom: 56px;
  }
  .company-dashboard {
    margin-bottom: 56px;
  }
  .company-sections {
    margin-bottom: 56px;
  }
  .profile-settings-container {
    margin-bottom: 56px;
  }
  .opted-rewards-container {
    margin-bottom: 56px;
  }
  .company-rewards-container {
    margin-bottom: 56px;
  }
  .settings-page {
    margin-bottom: 56px;
  }
  .admin-sections {
    margin-bottom: 56px;
  }
  .website-generator-container {
    margin-bottom: 56px;
  }
  .mobile-user-home {
    margin-bottom: 56px;
  }
  .items-management{
    margin-bottom: 56px;
  }
}


.scrollbar-style {
  &::-webkit-scrollbar {
    width: '8px';
    height: '8px';
  }
  &::-webkit-scrollbar-track {
    background: '#2e3032';
  }
  &::-webkit-scrollbar-thumb {
    background: '#888';
    border-radius: '4px';
  }
  &::-webkit-scrollbar-thumb:hover {
    background: '#555';
  }
  scrollbar-width: 'thin';
  scrollbar-color: '#888 #2e3032';
};

@media (max-width: 768px) {
  .main-layout {
    padding-bottom: 56px; /* Height of the BottomNavigation */
  }
}

.firebase-emulator-warning {
  display: none;
}

.opted-rewards-filter-input{
  background-color: #2C2D2E;
}

.company-rewards-filter-input{
  background-color: #3A3B3C;
}

.opted-rewards-container{
  background: linear-gradient(to bottom, #3498db 0%, #3498db 30%, #f0f3f6 85%);
}

.company-rewards-container{
  background: linear-gradient(to bottom, #3498db 0%, #3498db 30%, #f0f3f6 85%);
}

.company-dashboard{
  background: linear-gradient(to bottom, #3498db 0%, #3498db 17%, #f0f3f6 30%);
}

.settings-page{
  background: linear-gradient(to bottom, #3498db 0%, #3498db 17%, #f0f3f6 42%);
  height: 100vh;
}
/*! add a lime green border to all elements */
/* * {
  border: 1px solid lime;
} */