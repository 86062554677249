/* Home page styles */
.home-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 1rem;
}

.hero-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4rem;
  padding: 2rem 0;
}

.hero-content {
  flex: 1;
  max-width: 600px;
}

.hero-content h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
  color: #E8E6E3;
}

.hero-subtitle {
  font-size: 1.2rem;
  margin-bottom: 2rem;
  color: #B0B3B8;
}

.hero-description {
  margin-bottom: 2rem;
  color: #B0B3B8;
}

.hero-cta {
  display: flex;
  gap: 1rem;
}

.hero-image {
  flex: 1;
  display: flex;
  transform: scale(0.6);
  justify-content: center;
  align-items: center;
  overflow: hidden;
  max-width: 70%;
  /* Add styles for your hero image or illustration */
}
/* zoom out the image based on the width of the screen */
@media (max-width: 768px) {
  .hero-image {
    display: none;
  }
}

.features-section {
  margin-bottom: 4rem;
}

.feature-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
}

.feature-item {
  background-color: #242526;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.how-it-works {
  margin-bottom: 4rem;
}

.steps {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
}

.step {
  flex: 1;
  text-align: center;
  padding: 1.5rem;
  background-color: #242526;
  border-radius: 8px;
}

.step-number {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  background-color: #4A4B4C;
  color: #E8E6E3;
  font-weight: bold;
  margin-bottom: 1rem;
}

.contact-section {
  text-align: center;
  margin-bottom: 4rem;
}

.contact-form {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  margin: 0 auto;
}

.contact-form input,
.contact-form textarea {
  margin-bottom: 1rem;
  padding: 0.5rem;
  border: 1px solid #4E4F50;
  border-radius: 4px;
  background-color: #3A3B3C;
  color: #E8E6E3;
}

.contact-form textarea {
  min-height: 150px;
}

.pwa-section {
  text-align: center;
  margin-bottom: 4rem;
}

.btn {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  font-weight: bold;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.btn-primary {
  background-color: #4E9EF5;
  color: #E8E6E3;
}

.btn-primary:hover {
  background-color: #3A8DE5;
}

.btn-secondary {
  background-color: transparent;
  color: #4E9EF5;
  border: 1px solid #4E9EF5;
}

.btn-secondary:hover {
  background-color: rgba(78, 158, 245, 0.1);
}

@media (max-width: 768px) {
  .hero-section {
    flex-direction: column;
    text-align: center;
  }

  .hero-content {
    margin-bottom: 2rem;
  }

  .hero-cta {
    justify-content: center;
  }

  .steps {
    flex-direction: column;
  }

  .feature-list {
    grid-template-columns: 1fr;
  }
}

.google-signup-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.google-signup-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #4285F4;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.google-signup-button:hover {
  background-color: #357ae8;
}

.google-signup-button svg {
  margin-right: 10px;
}

.google-login-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 15px;
  }
  
  .google-login-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #4285F4;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .google-login-button:hover {
    background-color: #357ae8;
  }
  
  .google-login-button svg {
    margin-right: 10px;
  }