.points-management {
  padding: 20px;
  background-color: #242526;
  color: #E8E6E3;
}

.points-management h4 {
  margin-bottom: 20px;
  color: #E8E6E3;
}

.points-management .MuiTabs-root {
  background-color: #3A3B3C;
  border-radius: 8px 8px 0 0;
}

.points-management .MuiTab-root {
  color: #E8E6E3;
}

.points-management .MuiTab-root.Mui-selected {
  color: #4E9EF5;
}

.points-management .MuiBox-root {
  background-color: #2C2D2E;
  padding: 20px;
  border-radius: 0 0 8px 8px;
}

.points-management .MuiTextField-root {
  margin-bottom: 16px;
}

.points-management .MuiInputBase-root {
  color: #E8E6E3;
}

.points-management .MuiInputLabel-root {
  color: #B0B3B8;
}

.points-management .MuiOutlinedInput-notchedOutline {
  border-color: #3A3B3C;
}

.points-management .MuiButton-contained {
  background-color: #4E9EF5;
  color: #E8E6E3;
}

.points-management .MuiButton-contained:hover {
  background-color: #6FB2FF;
}

.points-management .MuiSwitch-root .MuiSwitch-switchBase.Mui-checked {
  color: #4E9EF5;
}

.points-management .MuiSwitch-root .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
  background-color: #4E9EF5;
}

.points-management .MuiIconButton-root {
  color: #E8E6E3;
}

.points-management .MuiTypography-root {
  color: #E8E6E3;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .points-management {
    padding: 10px;
  }
}

