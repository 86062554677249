.view-company-details {
  padding: 20px;
}

.view-company-details table {
  width: 100%;
  border-collapse: collapse;
}

.view-company-details th, .view-company-details td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.view-company-details th {
  background-color: #f2f2f2;
}

.view-company-details tr:nth-child(even) {
  background-color: #f9f9f9;
}