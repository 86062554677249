.admin-dashboard {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.admin-dashboard h1 {
  text-align: center;
  margin-bottom: 2rem;
  color: #333;
}

.admin-sections {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1rem;
}

.admin-section-link {
  display: block;
  background-color: #f0f0f0;
  color: #333;
  text-decoration: none;
  padding: 1.5rem;
  border-radius: 8px;
  text-align: center;
  font-weight: bold;
  transition: background-color 0.3s, transform 0.3s;
}

.admin-section-link:hover {
  background-color: #e0e0e0;
  transform: translateY(-5px);
}

@media (max-width: 768px) {
  .admin-sections {
    grid-template-columns: 1fr;
  }
}