body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1, h2, h3, p {
  margin: 0;
}

.template1-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

@media (max-width: 768px) {
  .template1-container {
    font-size: 14px;
  }
}