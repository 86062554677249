.company-dashboard {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  font-family: Arial, sans-serif;
}

.company-dashboard h1 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 1.5rem;
  text-align: center;
}

.language-toggle {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
}

.language-toggle button {
  background-color: #f0f0f0;
  border: none;
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.language-toggle button:hover {
  background-color: #e0e0e0;
}

.company-sections {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
}

.company-section-link {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #72a7df;
  color: white;
  text-decoration: none;
  padding: 1.5rem;
  border-radius: 8px;
  font-size: 1.1rem;
  font-weight: bold;
  text-align: center;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.company-section-link:hover {
  background-color: #0056b3;
  transform: translateY(-3px);
}

@media (max-width: 768px) {
  .company-dashboard {
    padding: 1rem;
  }

  .company-dashboard h1 {
    font-size: 2rem;
  }

  .company-sections {
    grid-template-columns: 1fr;
  }
}